import { Dispatch, SetStateAction, useEffect, useId, useRef, useState } from 'react'
import { AnimationControls, useAnimation } from 'framer-motion'
import { isAndroid } from 'react-device-detect'
import NativeBridgeContainer from '@/containers/app/NativeBridgeContainer'

export type BottomSheetControlsLegacyType<T = any> = {
  handleOpen(): void
  handleClose(): void
  mount: boolean
  bottomSheetData: T
  setBottomSheetData: Dispatch<SetStateAction<T>>
  controls: AnimationControls
  setMountAfterCallbackRef: (callback: () => void) => void
  setIsClosable: (isClosable: boolean) => void
}

interface UseBottomSheetLegacyProps {
  /**
   * BottomSheet를 사용자가 닫을 수 있는지 여부
   * 예를 들어, 우천시 사용자가 주문을 하지 못하도록 닫을 수 없는 BottomSheet를 노출
   */
  isClosable?: boolean
}

const useBottomSheetLegacy = <T = unknown>(props?: UseBottomSheetLegacyProps): BottomSheetControlsLegacyType<T> => {
  const { isClosable = true } = props || {}
  const keyId = useId()
  const backKeyHandlingKeyRef = useRef(`bottom-sheet-${keyId}`)
  const { addBackKeyHandler, removeBackHandler } = NativeBridgeContainer.useContainer()
  const [mount, setMount] = useState(false)
  const [bottomSheetData, setBottomSheetData] = useState(<T>{})
  const controls = useAnimation()
  const mountAfterCallbackRef = useRef<() => void>()
  const isClosableRef = useRef<boolean>(isClosable)

  useEffect(() => {
    return () => {
      if (mount) {
        removeBackHandler(backKeyHandlingKeyRef.current)
      }
    }
  }, [mount])

  const handleOpen = () => {
    // mount
    setMount(true)
    if (isAndroid && isClosableRef.current) {
      addBackKeyHandler(backKeyHandlingKeyRef.current, () => {
        handleClose()
        return true
      })
    }
    // open animation
    setTimeout(() => {
      controls.start('visible')
      mountAfterCallbackRef.current?.()
    }, 100)
  }

  const setIsClosable = (isClosableNew: boolean) => {
    isClosableRef.current = isClosableNew
  }

  const handleClose = () => {
    if (!isClosableRef.current) return
    // close animation 시작
    controls.start('hidden')
    if (isAndroid) {
      removeBackHandler(backKeyHandlingKeyRef.current)
    }

    // unmount
    setTimeout(() => {
      setBottomSheetData(<T>{})
      mountAfterCallbackRef.current = undefined
      setMount(false)
    }, 300)
  }

  const setMountAfterCallbackRef = (callback: () => void) => {
    mountAfterCallbackRef.current = callback
  }

  return {
    handleOpen,
    handleClose,
    mount,
    bottomSheetData,
    setBottomSheetData,
    controls,
    setMountAfterCallbackRef,
    setIsClosable
  }
}

export default useBottomSheetLegacy
