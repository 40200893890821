import { WalletUnit } from '@/constants/common'
import { LocaleEnum } from '@/constants/i18n'
import { numberWithCommas } from '@/utils/number'

const toNumber = (value?: string | number) => {
  if (value === undefined) {
    return 0
  }
  if (typeof value === 'string') {
    return Number(value)
  }
  return value
}

const getUnitPriceByLocale = (locale: LocaleEnum) => {
  const base = {
    style: 'currency',
    maximumFractionDigits: 3,
    minimumFractionDigits: 0
  }
  switch (locale) {
    case LocaleEnum.Arabic_SaudiArabia:
      return {
        locale: 'us-EN',
        currency: 'SAR',
        ...base
      }
    default:
      return {
        locale: LocaleEnum.Korean,
        currency: 'KRW',
        ...base
      }
  }
}

export const toUnitPrice = (price = 0, locale: LocaleEnum) => {
  switch (locale) {
    case LocaleEnum.Arabic_SaudiArabia:
      return `${numberWithCommas(price)} SR`
    case LocaleEnum.Korean:
      return `${numberWithCommas(price)}원`
    default: {
      return new Intl.NumberFormat(locale, {
        ...getUnitPriceByLocale(locale)
      }).format(price)
    }
  }
}

const toUnitPoint = (point = 0) => {
  return `${numberWithCommas(point)} ${WalletUnit}`
}

export const PriceUtils = {
  toUnitPoint,
  toUnitPrice,
  toNumber
}
