import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { LocaleEnum } from '@/constants/i18n'
import { DateUtils } from '@/utils/date'
import { PriceUtils } from '@/utils/price'

export const localeDefault = process.env.NEXT_PUBLIC_LOCALE_DEFAULT || LocaleEnum.Korean

const useLocale = () => {
  const { locale, replace, pathname, query } = useRouter()
  const localeCurrent = (locale || localeDefault) as LocaleEnum
  const setLocale = useCallback(
    async (localeEnum: LocaleEnum) => {
      return replace({ pathname, query }, undefined, { locale: localeEnum })
    },
    [replace]
  )

  /**
   * @link PriceUtils.toUnitPrice
   */
  const toUnitPrice = (price = 0) => {
    return PriceUtils.toUnitPrice(price, localeCurrent)
  }

  /**
   * @link DateUtils.formatMinSec
   * @param seconds
   */
  const formatMinSec = (seconds: number) => {
    return DateUtils.formatMinSec(seconds, localeCurrent)
  }

  /**
   * @link DateUtils.formatDate
   */
  const formatDate = (date: string, format: string) => {
    return DateUtils.formatDate(date, format, localeCurrent)
  }

  /**
   * @link DateUtils.formatHourMin
   */
  const formatHourMin = (timeStr: string) => {
    return DateUtils.formatHourMin(timeStr, localeCurrent)
  }

  return {
    locale: localeCurrent,
    setLocale,
    toUnitPrice,
    formatMinSec,
    formatDate,
    formatHourMin
  }
}

export default useLocale
