import dayjs, { extend as dayjsExtend } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { LocaleEnum } from '@/constants/i18n'

dayjsExtend(customParseFormat)
dayjsExtend(utc)
dayjsExtend(timezone)

/**
 * 입력 받은 초를 분:초로 변경
 */
const formatMinSec = (seconds: number, locale: LocaleEnum) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  if (locale === LocaleEnum.Korean) {
    return `${minutes}분 ${remainingSeconds.toString().padStart(2, '0')}초`
  }

  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
}

const removeSeconds = (timeString?: string | null) => {
  if (!timeString) {
    return ''
  }
  return timeString?.substring(0, 5)
}

const getBusinessHours = (
  openedAt: string | undefined | null,
  closedAt: string | undefined | null
): string | undefined => {
  if (!openedAt || !closedAt) {
    return undefined
  }
  const openedAtDayjs = dayjs(openedAt, 'HH:mm:ss')
  const closedAtDayjs = dayjs(closedAt, 'HH:mm:ss')

  if (!openedAtDayjs.isValid() || !closedAtDayjs.isValid()) {
    return undefined
  }

  return `${openedAtDayjs.format('HH:mm')}~${closedAtDayjs.format('HH:mm')}`
}

const getNowIsAble = (openedAt: string | undefined | null, closedAt: string | undefined | null) => {
  if (!openedAt || !closedAt) {
    return false
  }
  const currentTime = new Date()
  const currentHour = currentTime.getHours()
  const currentMinute = currentTime.getMinutes()

  const [openHour, openMinute] = openedAt.split(':').map(Number)
  const [closeHour, closeMinute] = closedAt.split(':').map(Number)

  const open = openHour * 60 + openMinute
  const close = closeHour * 60 + closeMinute
  const now = currentHour * 60 + currentMinute

  return open <= now && now <= close
}

const getNowIsAbleWithEmpty = (openedAt: string | undefined | null, closedAt: string | undefined | null) => {
  if (!openedAt || !closedAt) {
    return true
  }
  const currentTime = new Date()
  const currentHour = currentTime.getHours()
  const currentMinute = currentTime.getMinutes()

  const [openHour, openMinute] = openedAt.split(':').map(Number)
  const [closeHour, closeMinute] = closedAt.split(':').map(Number)

  const open = openHour * 60 + openMinute
  const close = closeHour * 60 + closeMinute
  const now = currentHour * 60 + currentMinute

  return open <= now && now <= close
}

const formatDate = (date: string, format: string, locale: LocaleEnum) => {
  const dayjsLocale = locale === LocaleEnum.Arabic_SaudiArabia ? 'en' : 'ko'
  return dayjs(date).locale(dayjsLocale).format(format)
}

/**
 * 입력받은 timeString을 형식에 맞춰서 변경
 * @param timeStr 00:00 과 같은 형식으로 입력
 * @param locale
 */
const formatHourMin = (timeStr: string, locale: LocaleEnum): string => {
  const timeParts = timeStr.split(':')
  const hoursTmp = parseInt(timeParts[0])
  const isAm = hoursTmp < 12
  const hours = (isAm ? hoursTmp : hoursTmp - 12).toString().padStart(2, '0')
  const minutes = parseInt(timeParts[1]).toString().padStart(2, '0')

  if (locale === LocaleEnum.Arabic_SaudiArabia) {
    return `${isAm ? 'AM' : 'PM'} ${hours}:${minutes}`
  }

  return `${isAm ? '오전' : '오후'} ${hours}시 ${minutes}분`
}

export enum DayEnum {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday'
}

export const DayIndexMap = {
  [DayEnum.Sunday]: 0,
  [DayEnum.Monday]: 1,
  [DayEnum.Tuesday]: 2,
  [DayEnum.Wednesday]: 3,
  [DayEnum.Thursday]: 4,
  [DayEnum.Friday]: 5,
  [DayEnum.Saturday]: 6
}
const getTimeZoneDate = (timezone: 'Asia/Riyadh' | 'Asia/Seoul') => {
  return dayjs().tz(timezone)
}

const getDayNameByTimeZone = (timezone: 'Asia/Riyadh' | 'Asia/Seoul', afterDay = 0) => {
  return Object.values(DayEnum)[(DateUtils.getTimeZoneDate(timezone).day() + afterDay) % 7]
}

export const DateUtils = {
  formatMinSec,
  removeSeconds,
  getBusinessHours,
  getNowIsAble,
  getNowIsAbleWithEmpty,
  formatDate,
  formatHourMin,
  getTimeZoneDate,
  getDayNameByTimeZone
}
